import config from 'config';
import type { CmsContentDictionary } from '$cms/index';
import { extendCmsText } from '$cms/index';
import { renderRichTextByKey, getShortTextByKey } from '$cms/getDataByKey';

export const getCmsData = (dictionary: CmsContentDictionary, source: string) => {
  const getShortText = getShortTextByKey.bind(dictionary);
  const getRichText = renderRichTextByKey.bind(dictionary);

  const openingHours = config.get<[string, string]>('public.opening.hours');
  const phoneNumber = config.get<string>('public.phone_number');
  const email = config.get<string>('public.email.support');

  return {
    sectionTitleDesktop: getRichText(
      'teamSupport.sectionTitle.desktop',
      <>
        Need help or have any
        <br />
        questions?
      </>
    ),
    sectionTitleMobile: getShortText(
      'teamSupport.sectionTitle.mobile',
      'Need a hand understanding what your car needs?'
    ),
    defaultContent: getShortText(
      'teamSupport.content',
      `Our in-house experts are here to answer you car questions, Monday to Friday, ${openingHours[0]} to ${openingHours[1]}.`
    ),
    callUsText: getShortText('teamSupport.callUs.text', 'Call us now'),
    callUsAreaCode: getShortText('customerService.contact.areaCode', '+44'),
    callUsPhone: getShortText('customerService.contact.phone', phoneNumber),
    emailUsTitle: getShortText('teamSupport.emailUs.title', 'Email us'),
    emailUsEmail: getShortText('customerService.contact.email', email),
    contactUsTitle: getShortText('teamSupport.contactUs.title', 'Email us'),
    contactUsFormLink: extendCmsText(
      getShortText(
        'teamSupport.contactUs.formLink',
        `https://fixter-feedback.typeform.com/to/gRmjCtMa#source={source}`
      ),
      {
        source,
      }
    ),
  };
};
