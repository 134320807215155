import classNames from 'classnames';

import { useAppContext } from '$util/AppContext';
import type { AppContextGlobal } from '$util/AppContextGlobal';

import { EmailUs } from '$quantum/molecules/EmailUs';
import { SectionTitle } from '$quantum/molecules/SectionTitle';
import { CallUsButton } from '$quantum/molecules/CallUsButton';
import { isFeatureContactUsFormActive } from '$util/features';
import { ContactUs } from '$quantum/molecules/ContactUs';
import { AnalyticsServiceFieldKey } from '$util/analytics/analyticsService/types';
import { Styled } from './styled';
import { getCmsData } from './helpers/cms';
import { sendInternalAnalytic } from './helpers/analytics';

export interface TeamSupportSectionProps {
  readonly isContactUs?: boolean;
  readonly isModalContext?: boolean;
  readonly content?: React.ReactNode;
  readonly className?: string;
  readonly title?: string | React.ReactNode;
  readonly source?: string;
}

export default function TeamSupportSection({
  isModalContext = false,
  content,
  className,
  title,
  source = '',
  ...props
}: TeamSupportSectionProps): JSX.Element {
  const {
    cmsContent: { dictionary },
    locale,
  } = useAppContext<AppContextGlobal>();

  const {
    defaultContent,
    callUsText,
    callUsAreaCode,
    callUsPhone,
    emailUsTitle,
    emailUsEmail,
    sectionTitleDesktop,
    contactUsTitle,
    contactUsFormLink,
  } = getCmsData(dictionary, source);

  const displayTitle = title ?? sectionTitleDesktop;
  const displayContent = content ?? defaultContent;

  return (
    <Styled
      className={classNames('TeamSupportSection', isModalContext && '-modal-context', className)}
      {...props}
    >
      <div className="TeamSupport__content">
        <div className="TeamSupport__title">
          <SectionTitle desktop={displayTitle} mobile={displayTitle} />
          <div className="TeamSupport__text">
            <p>{displayContent}</p>
          </div>
        </div>
      </div>

      <div className={classNames('TeamSupport__CTA-group', isModalContext && '-modal-context')}>
        <div className="TeamSupport__CTA-call-us">
          <CallUsButton
            text={callUsText}
            areaCode={callUsAreaCode}
            phoneNumber={callUsPhone}
            onClick={() => {
              sendInternalAnalytic(AnalyticsServiceFieldKey.TEAM_SUPPORT_SECTION_PHONE_CLICKED);
            }}
          />
        </div>
        <div className="TeamSupport__CTA-email-us">
          {isFeatureContactUsFormActive(locale) ? (
            <ContactUs
              title={contactUsTitle}
              formLink={contactUsFormLink}
              onClick={() => {
                sendInternalAnalytic(AnalyticsServiceFieldKey.TEAM_SUPPORT_SECTION_CONTACT_FORM_CLICKED);
              }}
            />
          ) : (
            <EmailUs
              title={emailUsTitle}
              email={emailUsEmail}
              onClick={() => {
                sendInternalAnalytic(AnalyticsServiceFieldKey.TEAM_SUPPORT_SECTION_EMAIL_CLICKED);
              }}
            />
          )}
        </div>
      </div>
    </Styled>
  );
}

TeamSupportSection.displayName = 'TeamSupportSection';
