import type { HTMLAttributes } from 'react';
import React from 'react';
import { ConfigProvider } from 'antd';
import { CustomerCommonHead } from '$layout/partials/CustomerCommonHead';
import AdminWarning from '$components/AdminWarning';
import { QueuedModals } from '@/components/organisms/QueuedModals';
import { CustomerTopMegaNavigationBar } from '@/components/templates/CustomerTopMegaNavigationBar';
import MainStyled from './styled';

export interface Props extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

export default function CustomerLayout({ children, ...props }: Props): JSX.Element {
  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        hashed: false,
        token: { fontFamily: 'Rubik, sans-serif' },
      }}
    >
      <MainStyled {...props}>
        <CustomerCommonHead />
        <AdminWarning />
        <CustomerTopMegaNavigationBar />
        {children}
        <QueuedModals />
      </MainStyled>
    </ConfigProvider>
  );
}
